.srv-validation-message,
.error {
  font-size: 12px;
  color: #e60c0c;
}
.cursor {
  cursor: pointer;
}
.order_type ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 40px;
  gap: 15px;
}

.order_type ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 150px;
  aspect-ratio: 1/0.85;
  justify-content: center;
  gap: 15px;
  border-radius: 2px;
  border: 2px solid #f0f0fb;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
}

.order_type ul li.disable {
  pointer-events: none;
  opacity: 0.5;
}

.order_type ul li:hover {
  background: #f0f0f0;
}

.order_type ul li.selected {
  background: #b9906d;
}
.input-radio-label__list label > label {
  position: absolute;
  top: 0px;
  left: 2px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 300;
  color: #6c757d;
  padding-bottom: 2px;
  margin: 0;
  transform: translateX(100%);
}
.input-radio-label__list label > label:not(::first-child) {
  display: none;
}
.order-blur {
  filter: blur(2px);
  pointer-events: none;
}

.custom-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

/* Modal Content */
.custom-modal-content {
  background-color: #fefefe;
  margin: 0% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

/* Close Button */
.custom-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

/* Close Button Hover Effect */
.custom-modal-close:hover {
  color: #000;
}

.custom-modal.show {
  display: block;
}
.payment-phone .PhoneInput {
  pointer-events: none;
  opacity: 0.8;
}

.login-change-resend {
  text-align: right;
  cursor: pointer;
  color: #1a66ff;
}
