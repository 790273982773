// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
//@import 'themes/red';
//@import 'themes/blue';
//@import 'themes/black';
//@import 'themes/green';

$nav-panel-height: 80px !default;
$nav-panel-bg: #fff;
// $product-card-hover-shadow: 0 0 0 2px rgba(#BA906D, .7) inset !default;
// $product-card-quickview-default-bg: #BA906D !default;