// @font-face {
//     font-family: 'Lato-Regular';
//     font-weight: 400;
//     src: url('../../public/fonts/Lato-Regular.woff2') format('woff2');
// }

// -------------------------
// --- Top bar css
// -------------------------
.topbar {
    background: #2C56A4;
    color: #fff;
    height: 100%;
    padding: 7px;
    line-height: 25px;

    @media screen and (max-width : 768px) {
        padding: 7px 0;
    }
}

.topbar__item {
    margin: 0 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .shop-title-text {
        margin-bottom: 0;
        font-size: 1rem;
        letter-spacing: 1.1px;

        @media screen and (max-width:992px) {
            letter-spacing: 1.0px;
            font-size: 14px;
            text-align: center;
        }

        .space-1 {
            padding-right: 9px;

            @media screen and (max-width:992px) {
                padding-right: 0px;
            }
        }

        .space-2 {
            padding-left: 9px;

            @media screen and (max-width:992px) {
                padding-left: 0px;
            }
        }

    }
}

@media screen and (min-width : 992px) {
    .nav-panel__indicators {
        margin-left: 0 !important;

    }
}

// -------------------------
// --- header css
//

.nav-panel__logo+.nav-panel__nav-links,
.nav-panel__departments+.nav-panel__nav-links {
    @include direction {
        #{$margin-inline-start}: auto;
    }
}

.megamenu {
    padding: 0px 30px 10px;

    @media screen and (min-width : 992px) {
        padding: 20px 21px 20px 62px;
    }


    @media screen and (max-width : 992px) {
        background: #efeff1;
        padding: 0;
        box-shadow: none !important;
    }
}

@media screen and (min-width : 992px) {
    .megamenu__links--level--0 {
        display: flex;
        overflow: auto;
        flex-wrap: wrap;

        .megamenu__item--with-submenu {
            display: flex;
            flex-wrap: wrap;
            max-width: 160px;
            width: 100%;
            height: 100%;
        }
    }

}

.megamenu__links--level--1>.megamenu__item>a {
    color: #000;

}

// .megamenu__links--level--0 {
//     @media screen and (min-width : 992px) {
//         display: inline-block;
//         border-right: 1px solid #a5a5a569;
//         max-width: 210px;
//         width: 100%;
//     }
// }


// .megamenu-list-items {
//     @media screen and (min-width : 992px) {
//         position: absolute;
//         left: 38%;
//         top: 0;
//     }
// }


.megamenu__links {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: baseline;



    @media screen and (max-width :991px) {
        display: block;
        text-align: left;
    }

    .megamenu__item--with-submenu {

        a {
            &:hover {
                color: #000;
            }
        }

        @media screen and (min-width : 992px) {
            a {
                &:hover {
                    color: #BA906D;
                }
            }
        }

        .megamenu__item {
            a {
                display: block;
                // padding-left: 10px;
                padding: 5px 0px;

                &:hover {
                    background-color: #bbbaba34;
                    color: #000;
                    width: 100%;

                    @media screen and (min-width : 992px) {
                        color: #BA906D;
                        background-color: transparent;
                    }

                }


            }
        }
    }

}

.megamenu__links--level--1 {
    display: none;
}

@media screen and (max-width :992px) {
    .megamenu__links--level--1 {
        display: none;

        // @media screen and (min-width : 992px) {}
    }


    // .megamenu__items__list:hover .megamenu__links--level--1 {
    //     display: block;
    // }

}

@media screen and (min-width :992px) {
    // .megamenu__links--level--1 {
    //     position: absolute;
    //     left: 200px;
    //     top: 0;
    //     height: 100%;
    //     width: 100%;
    //     overflow: auto;
    // }

    .megamenu__links--level--0>.megamenu__item>a+.megamenu__links {
        border-top: 0 !important;
    }



    .megamenu__items__list:hover {
        color: #ba906dd4 !important;
    }

    .megamenu__links--level--1 {
        .megamenu__item {
            // padding-left: 45px;

        }
    }

    .megamenu__links--level--0>.megamenu__item>a+.megamenu__links {
        width: 400px !important;
        padding-right: 30px !important;

    }
}

@media screen and (min-width : 992px) {
    .megamenu__links--level--0>.megamenu__item {
        padding: 4px 0;
    }
}

.megamenu__links--level--0 {
    &>.megamenu__item>a+.megamenu__links {
        width: 227px;
        // display: block;

        @media screen and(max-width:1199px) {
            width: 180px;
        }

        @media screen and(max-width:992px) {
            width: 100%;
        }

    }
}

.megamenu__links--level--0>.megamenu__item+.megamenu__item--with-submenu,
.megamenu__links--level--0>.megamenu__item--with-submenu+.megamenu__item {
    @media screen and (min-width : 992px) {
        margin-top: 0 !important;
    }
}

// -------------------------
// --- Banner css
//

.block-slideshow__slide-content {
    color: #fff;
}

.btn-primary {
    background-color: #000;
    color: #BA906D;
    border-color: #2c2c2c;

    &:hover {
        background-color: #fff;
        color: #000;
    }
}

// -------------------------
// --- Block features  css
// -------------------------
.block-features__list {
    .block-features__item {
        background: #efeff1;
    }
}

// -------------------------
// --- All heading color  css
// 

.block-header__title {
    color: #BA906D;
}

// -------------------------
// --- Product css
// -------------------------

.product-image__img {
    object-fit: cover;
}

// -------------------------
// --- Product css
//

// -------------------------
// --- Hunderdshand tools css
//
.block-banner__body {
    color: #fff;

    &:hover {
        color: #fff;
    }

    .block-banner__image {
        background-size: cover;

    }

}

// -------------------------
// --- Slider bar none css
//

.block-products-carousel {
    display: none;
}

// -------------------------
// --- Table spec name css 
//


.spec__name {
    color: #000;
}

// -------------------------
// --- social icon remove
//

.share-links__list {
    display: none;
}

// -------------------------
// --- header css icon start
//
.nav-panel__nav-links {
    #click {
        color: #fff;
        font-size: 50px;
        display: none;
    }

    .mobile-menu-bar {
        color: #3d464d;
        font-size: 20px;
        display: none;
    }
}

// -------------------------
// --- header css icon end
//

.block-products__list {
    width: 100%;
}

.block-products__body {
    margin-bottom: 46px;
}

@media screen and (max-width :767px) {
    .block-slideshow__slide-content {
        top: 82px;

    }

    .block-slideshow__slide-text {
        display: block;
    }
}

.nav-panel__indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 0;

    @media screen and (max-width:500px) {
        grid-gap: 10px;
    }
}

@media screen and (max-width : 568px) {
    .nav-panel__indicators {
        margin-right: 0px !important;
    }
}



@media screen and (max-width : 991px) {
    .nav-panel__logo {
        padding-left: 40px;
    }

    .nav-panel__indicators {
        margin-right: 20px;
    }

    .nav-panel__nav-links {
        position: relative;
        width: 100%;

        #click {
            position: absolute;
            right: 0;
            top: 40%;
        }

        .mobile-menu-bar {
            position: absolute;
            left: 0;
            top: 35%;
            display: block;
            transform: translateX(-140px);

        }

        .custom-header {
            position: fixed;
            top: 0;
            left: 0;
            max-width: 300px;
            width: 100%;
            height: 100vh;
            background-color: #fff;
            text-align: center;
            flex-direction: column;
            transform: translateX(-100%);
            transition: 0.5s all ease-in-out;
            z-index: 99;

            // &::before {
            //     content: '';
            //     position: fixed;
            //     top: 0;
            //     left: 0;
            //     width: 100vw;
            //     height: 100%;
            //     background-color: #00000096;
            //     transform: translateX(-100vw);
            //     z-index: -3;
            //     // transition: all 0.2s ease;
            // }

            &::after {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                z-index: -2;
                transform: translateX(-100%);
                // transition: all 0.2s ease;
            }
        }

        #click:checked~::before {
            transform: translateX(0);
        }

        #click:checked~::after {
            transform: translateX(0);
        }

        #click:checked~.custom-header {
            transform: translateX(0);

        }
    }

    .custom-header .nav-links__megamenu {
        position: relative;
        opacity: 1;
        visibility: visible;
        left: 0 !important;
        width: 100%;
        transform: rotateX(0deg);

    }

    .megamenu__links .megamenu__item--with-submenu .megamenu__item a {
        border-bottom: 1px solid rgba(220, 220, 220, 0.548);
        padding: 10px 30px;
    }

    .items-megamenu-heading {
        display: block;
        font-size: 15px;
        padding: 10px 16px;
        border-bottom: 1px solid gainsboro;
        font-weight: 600;
    }

    // .megamenu__items__list {
    //     padding: 15px 0 !important;
    // }

    .megamenu__links--level--0>.megamenu__item>a+.megamenu__links {
        margin-top: 0 !important;
        padding-top: 0 !important;
        // display: none;
        background-color: #fff;
        padding: 0 10px;
    }

    .megamenu__links--level--0 {

        .megamenu__item {
            // border-bottom: 1px solid #9999994b;
            // padding: 10px 20px;
            margin-top: 0 !important;
            font-size: 14px;
        }
    }

    .close-menu-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 99;

        .close-btn {

            display: flex;
            justify-content: end;
            padding: 10px 15px;
            width: 100%;

            &::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 2px;
                background-color: #9999994b;
                top: 0;
                right: 55px;
            }

            img {
                width: 25px;

            }
        }
    }

    .nav-links__item {
        border-bottom: 1px solid #9999994b;
        position: relative;
        // height: 45px;

        .menu-close-btn {
            width: 100%;
        }
    }

    .nav-links__item--with-submenu {
        position: relative;
        // overflow: auto;

        span {
            &::before {
                content: '';
                position: absolute;
                // width: 2px;
                height: 100%;
                background-color: #9999994b;
                right: 55px;
                top: 0;
                z-index: 99;
            }
        }
    }

    .menu {
        padding: 0 0;
        box-shadow: none;
    }

    .menu--layout--classic {
        width: 100%;
        background-color: #efeff1;
        // padding: 0 15px;


        li {
            a {
                border-bottom: 1px solid #9999994b;
                padding: 16px 30px !important;

            }
        }
    }

    .nav-links__item>a>span {
        height: 45px !important;
    }

    .nav-links__item--with-submenu>a svg {
        right: 22px !important;
    }

    .nav-links__item>a {
        font-size: 14px;
    }

    .nav-links__item>a>span {
        height: 45px;
    }

    // .nav-links__item--with-submenu {
    //     pointer-events: none;
    // }

    .nav-links__menu,
    .nav-links__megamenu {
        position: absolute;
        visibility: visible;
        transform-origin: top;
        transform: rotateX(0deg);
        opacity: 1;
        transition: transform 0.2s, opacity 0.2s;
        width: 100%;

    }

    .block-header {
        margin-top: 18px;
    }

    .block-product-columns__item+.block-product-columns__item {
        margin: 13px 0;
    }

    .spec__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .spec__name {
            margin-bottom: 0;
        }
    }

    .nav-panel__logo {
        a {
            svg {
                height: 100px !important;
                width: 100px !important;
                // width: calc();
            }
        }
    }

    .typography {
        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }
    }

}

.overlay-menu {
    position: relative;



    @media screen and (max-width : 992px) {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -40px;
            width: 100%;
            height: 100vh;
            background-color: #00000088;
            z-index: 99;
        }
    }

    @media screen and (max-width : 768px) {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -63px;
            width: 100%;
            height: 100vh;
            background-color: #00000088;
            z-index: 99;
        }
    }
}

.indicator--trigger--click.indicator--opened .indicator__area,
.indicator:hover .indicator__area {
    background-color: transparent;
}

// -------------------------
// --- popular categories css start
//

.category-card__body {
    &:hover {
        box-shadow: 4px 2px 4px #d3d0d094;
    }
}

.category--compact {
    display: block;

    .category__items__card {
        color: #000;


        &:hover {
            color: #000;

        }

        .category-card__body {
            .category-card__image {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                overflow: hidden;
                object-fit: cover;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                a {
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }


}

.category-card__name {
    a {
        &:hover {
            color: #000;
        }
    }
}

.product-card__info {
    a {
        &:hover {
            color: #000;
        }
    }
}

.block-products__list-item .product-card .product-card__actions {
    padding: 0 0 15px;
}

.footer-links__list {
    a {
        &:hover {
            color: #000;
        }
    }
}

.product-card__link {
    display: block;
    color: #000;
    width: 100%;
    padding: 0 15px;
    font-size: 1.4rem;
    line-height: 30px;

    &:hover {
        color: #000;
    }
}

.product-card__title_name {
    display: block;
    width: 100%;
    padding: 13px 20px 0 0;
    text-decoration: none;
    color: #3d464d;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        color: #3d464d;
    }
}

.product-card__name {
    .product-card__title_name {
        color: #000;
        display: block;

    }
}

.indicator__icon--open {
    position: relative;
}

.block-2 {
    max-width: 400px;
    width: 100%;
    margin: 25px auto 10px;
}

.accordion-list {
    max-width: 800px;
    width: 100%;
    margin: 25px auto 10px;
}

.accordion-button:not(.collapsed) {
    color: #000;
}

.accordion-button:focus {
    box-shadow: none;
}

.conditions {
    margin: 30px 0;

    h2 {
        font-size: 30px;
        line-height: 30px;
    }

    p {
        font-size: 17px;
        line-height: 30px;
    }

    ul {
        padding: 0 12px;

        li {
            font-size: 17px;
            line-height: 30px;
        }
    }
}

.breadcrumb-item a:hover {
    color: #000;
}

.topbar-link {
    &:hover {
        color: #fff;
    }
}

.post-card__name {
    a {
        &:hover {
            color: #000;
        }
    }
}

.widget-categories__row {
    a {
        &:hover {
            color: #000;
        }
    }
}

.widget-posts__name {
    a {
        &:hover {
            color: #000;
        }
    }
}

.block-products__featured {
    display: none !important;
}

// @media screen and (min-width : 992px) {
//     .block-products__featured {
//         max-height: 640px;
//     }
// }

.product__content {
    margin-top: 20px;
}

.nav-links__megamenu--size--nl {
    width: 80%;
    // left: 200px !important;
}

@media screen and (min-width : 767px) {
    .block-products__list-item {
        display: flex;
        width: calc(33.3333% - #{$local-product-margin * 2}) !important;
        margin: $local-product-margin;

        .product-card {
            width: 100%;
            flex-shrink: 0;
        }
    }
}

@media screen and (min-width : 1199px) {
    .block-products__list-item {
        display: flex;
        width: calc(25% - #{$local-product-margin * 2}) !important;
        margin: $local-product-margin;

        .product-card {
            width: 100%;
            flex-shrink: 0;
        }
    }
}

.widget-products__name {
    a {
        color: #000;

        &:hover {
            color: #000;
        }
    }
}

@media screen and (max-width : 768px) {
    .about-us__body {
        padding: 0px 0;
    }
}

@media screen and (min-width : 768px) {
    .about-us__body {
        padding: 0px 92px 45px;
        margin-top: -440px;
    }
}

.page-header__title {
    padding-top: 30px;
}

.product__rating-legend a:hover {
    color: #000;
}

.search--location--indicator .search__suggestions {
    height: 70vh;
    overflow: auto;
}

.widget-categories__subs a:hover {
    color: #000;
}

.shop-layout__content {
    width: 100% !important;
}

@media screen and (min-width :992px) {
    .products-list[data-layout=grid-3-sidebar] .products-list__item {
        width: calc(25% - 12px) !important;
        margin: 6px 6px;
    }
}

.about-us__title {
    margin-bottom: 32px !important;
}

.teammates__item {

    .teammate__avatar {

        img {
            width: 256px;
            margin: auto;
        }
    }

    .outer-text-lvy {
        font-size: 17px;
    }
}

header.site__header {
    position: -webkit-sticky;
    top: 0;
    z-index: 10;
    position: sticky;
}

.about-us__teammates {
    .slick-slide {
        max-width: 800px !important;
        width: 100% !important;
    }
}

.block-slideshow__slide-image {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: linear-gradient(100deg, rgb(0, 0, 0), rgba(7, 7, 7, 0));
    }
}

@media screen and (min-width : 992px) {
    .nav-links__list {
        display: flex;
        align-items: center;
    }
}




.block-features__item {
    .block-features__icon {
        img {
            width: 55px;
            height: 55px;
        }
    }

    .block-features__content {
        .block-features__title {
            color: #BA906D;
        }
    }
}



.all-dishes {
    .product-items {
        display: flex;
        align-items: center;
        padding-left: 10px;

        @media screen and (max-width: 992px) {
            justify-content: center;
        }

        img {
            max-width: 220px;
            width: 100%;
            margin: 20px 0 40px;
        }
    }

    .product-items-list {
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 10px;

        @media screen and (max-width: 992px) {
            justify-content: center;
        }

        img {
            max-width: 220px;
            width: 100%;
            margin: 20px 0 40px;
        }
    }

    .product {
        display: flex;
        align-items: center;
        justify-content: center;


        img {
            max-width: 220px;
            width: 100%;
            margin: 20px 0 40px;
        }
    }
}

.block-features--layout--boxed .block-features__item {
    padding: 30px !important;
}

.block-features--layout--boxed {
    margin-bottom: 20px !important;
}

.indicator__dropdown {
    .dropcart {
        // height: 100%;
        overflow: auto;
        max-height: 490px;

        .dropcart__products-list {
            max-height: 280px;
            overflow: auto;
        }
    }
}

.dropcart__product-name a:hover {
    color: #000;
}

.slick-prevent-click {
    .slick-slide {
        float: none !important;
    }
}

.block-categories__list {
    .block-categories__item {

        @media screen and (max-width: 992px) {
            width: calc(50% - 12px);
        }

        @media screen and (max-width: 678px) {
            width: calc(100% - 12px);
        }
    }
}

// order-confirm css start

.order-confirm {
    max-width: 800px;
    width: 100%;
    margin: 50px auto;
    background-color: #F8F6F4;
    padding: 90px 70px;

    h1 {
        font-size: 30px;
        line-height: 39px;
        color: #BA906D;
        margin-bottom: 0;
        text-align: center;

    }

    .user-confirm-order {
        padding: 65px 0 55px;

        h4 {
            font-size: 24px;
            color: #000000;
            font-weight: 400;
        }

        span {
            font-weight: 600;
        }

        .user-id {
            padding-bottom: 25px;
        }
    }

    .issues {
        text-align: center;
        font-size: 19px;
        color: #000000;
        font-style: italic;
        padding: 50px 0 28px;
        margin-bottom: 0;
    }

    .business {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        color: #000000;
        margin-bottom: 0;
    }

    .last-logo {
        text-align: center;
        padding-top: 65px;

        img {
            width: 200px;

        }
    }
}

.order-table tr {
    padding: 30px;
}

.modal-form {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
}

.account-menu__form-forgot {
    position: relative;

    input {
        border: 1px solid #928f8f;
        padding: 8px 10px;
        border-radius: 2px;
    }

    p {
        position: absolute;
        top: 8px;
        right: 10px;
        margin-bottom: 0;
    }
}

.account-menu {
    box-shadow: none;
    width: 100%;

    // @media screen and (max-width : 768px) {
    //     box-shadow: 0 5px 12px rgba(0, 0, 0, 0.25);
    // }        

    .account-menu__form {
        padding: 20px 10px 40px;


        .account-menu__form-title {
            padding: 0;
        }


    }

    .payment-phone {
        position: relative;

        p {
            position: absolute;
            top: 8px;
            right: 10px;
            margin-bottom: 0;
        }
    }

    .PhoneInputCountry {
        border: 1px solid #928f8f;
        padding: 6px;
        box-shadow: none;
        border-radius: 2px 0 0 2px;
        margin-right: -2px;
    }

    input.PhoneInputInput {
        border: 1px solid #928f8f;
        padding: 8px 10px;
        border-radius: 0 2px 2px 0;
        border-left: 0px;
    }

}

.account-menu__form-forgot-link {
    align-items: flex-start !important;

    &:hover {
        background-color: transparent;
    }
}

.cart-table__column--price::before,
.cart-table__column--quantity::before,
.cart-table__column--total::before {

    @media screen and (max-width:767px) {
        text-align: left !important;
    }
}

.proc-ptm {
    border: 1px solid transparent;
    font-weight: 600;
    padding: 13px 0;

    &:hover {
        border: 1px solid #000;
    }
}

.account_form-link_forgot {
    text-align: center;
    color: #6c757d;
    transition: color 0.1s;
    font-size: 14px;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 10px;

    &:hover {
        color: inherit;
        color: #6c757d;
    }
}

.cart-table__product-name {
    &:hover {
        color: #000;
    }
}

.product-card {
    position: relative;

    &.product-out-of-stock {
        pointer-events: none;

        &::before {
            content: 'Out of Stock';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #00000091;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 20px;
        }

        cursor: not-allowed;
    }



}

.overlay-checkout {
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // background-color: black;
    // z-index: 99999;
}

// #root {
//     overflow: hidden;

//     &::before {
//         content: "";
//         position: absolute;
//         left: 0;
//         top: 0;
//         width: 100%;
//         min-height: 100%;
//         background-color: rgba(0, 0, 0, 0.219);
//         z-index: 9;

//     }
// }

.css-yk16xz-control {
    border-radius: 2px !important;
}

.css-1pahdxg-control {
    border-radius: 2px !important;
}

@media screen and(max-width: 768px) {
    .custom-modal-content {
        width: 100% !important;
    }
}

.custom-modal-center {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.indicator__dropdown {
    @media screen and (max-width : 768px) {
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.25) !important;
    }
}

.reward-program {
    ul {
        list-style: none;
    }
}